<template>
  <div class="order-summery-card">
    <div>
      <div class="text-center lg:mt-0 mt-12 text-bluePrimary pt-6 lora-bold">
        {{ content.yourOrderSummery }}
      </div>
      <hr style="color: #37328b00" class="my-4" />
      <div class="flex justify-between px-4 xl:px-6">
        <div class="col-span-2 lora-bold">
          {{ content.itemSpecifications }}
        </div>
        <div class="col-span-1 lora-bold text-align-end">
          {{ content.cost }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-4">
        <div class="col-span-2 me-4 text-start">
          {{ content.container }}: {{ containerText }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ containerPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-2">
        <div class="col-span-2 me-4 text-start">
          {{ content.scent }}: {{ scentText }}
        </div>
        <div
          v-if="scentPrice"
          class="col-span-1 whitespace-nowrap text-align-end"
        >
          {{ scentPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-2">
        <div class="col-span-2 me-4 text-start">
          {{ content.wick }}: {{ wickText }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ wickPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-2">
        <div class="col-span-2 me-4 text-start">
          {{ content.color }}: {{ colorText }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ colorPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-2">
        <div class="col-span-2 me-4 text-start">
          {{ content.packagingOption }}: {{ packagingOption }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ packagingPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-2">
        <div class="col-span-2 me-4 text-start">
          {{ content.brandedLabel }}: {{ brandedLabel }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ labelPrice }} {{ content.iqd }}
        </div>
      </div>
      <hr style="color: #37328b22" class="my-4 mx-6" />

      <div class="flex justify-between px-4 xl:px-6 mt-4 text-size-16">
        <div></div>
        <div class="whitespace-nowrap">{{ content.totalPerItem }}:</div>
        <div class="text-align-end whitespace-nowrap">
          {{ totalPerItem }} {{ content.iqd }}
        </div>
      </div>
      <div
        class="flex justify-between flex-wrap px-4 xl:px-6 mt-4 text-size-16"
      >
        <div></div>
        <div class="whitespace-nowrap">{{ content.quantity }}:</div>
        <div class="text-align-end whitespace-nowrap">x {{ quantity }}</div>
      </div>
      <div class="flex justify-between flex-wrap px-4 xl:px-6 text-size-16">
        <div></div>
        <div class="col-span-4 whitespace-nowrap">
          <hr style="color: #37328b22" class="my-4" />
        </div>
      </div>
      <div
        class="
          flex
          justify-between
          flex-wrap
          px-4
          gap-5
          xl:px-6
          mt-4
          text-size-16
          pb-6
          flex-col-reverse
          items-end
        "
      >
        <div class="flex justify-between">
          <button
            @click.prevent="print()"
            class="
              header-btn
              text-bluePrimary
              hover:text-white hover:bg-bluePrimary
              lora-bold
              whitespace-nowrap
              bg-blueSecondary
              duration-300
            "
          >
            {{ content.printEstimate }}
          </button>
        </div>
        <div class="w-40 flex items-center justify-between">
          <div class="col-span-1 flex items-center whitespace-nowrap">
            {{ content.total }}:
          </div>
          <div
            class="
              col-span-2
              flex
              items-center
              justify-end
              text-align-end
              whitespace-nowrap
            "
          >
            {{ totalPrice }} {{ content.iqd }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div class="print-section" :class="{ 'is-printing': printing }">
      <div ref="receipt" class="receipt w-full h-full px-32 overflow-visible">
        <div class="flex justify-between items-center">
          <div>
            <div class="text-4xl font-bold text-bluePrimary">Lala Candles</div>
          </div>
          <img src="/static/images/header-logo.png" class="h-40 w-40" alt="" />
        </div>
        <hr class="border-grayBorder my-8" />
        <div class="col-6">
          <div class="flex">
            <div class="mr-4">Name :</div>
            <div class="font-bold">{{ request?.name }}</div>
          </div>
          <div>Email : {{ request?.email }}</div>
          <div>Phone : {{ request?.phone }}</div>
          <!--  <div>city : {{ user?.city?.name }}</div>-->
          <div>Date : {{ new Date().toLocaleDateString() }}</div>
          <div>Notes : {{ request?.notes }}</div>
        </div>
        <div>
          <hr class="border-grayBorder my-8" />

          <div class="lg:mt-0 mt-12 text-3xl text-bluePrimary pt-6 lora-bold">
            {{ content.yourOrderSummery }}
          </div>
          <hr style="color: #37328b00" class="my-4" />
          <div class="invoice-text">
            <div class="flex justify-between w-full py-8">
              <div class="me-4 text-start">
                {{ content.itemSpecifications }}
              </div>
              <div class="whitespace-nowrap text-align-end">
                {{ content.cost }}
              </div>
            </div>
            <div class="flex justify-between border-b border-grayBorder py-4">
              <div class="col-span-2 me-4 text-start">
                {{ content.container }}: {{ containerText }}
              </div>
              <div class="col-span-1 whitespace-nowrap text-align-end">
                {{ containerPrice }} {{ content.iqd }}
              </div>
            </div>
            <div
              class="flex justify-between border-b border-grayBorder py-4 my-2"
            >
              <div class="col-span-2 w-full flex justify-between text-start">
                <span>{{ content.scent }} </span> <span>{{ scentText }}</span>
              </div>
              <div
                v-if="scentPrice"
                class="col-span-1 whitespace-nowrap text-align-end"
              >
                {{ scentPrice }} {{ content.iqd }}
              </div>
            </div>
            <div
              class="flex justify-between border-b border-grayBorder py-4 my-2"
            >
              <div class="col-span-2 me-4 text-start">
                {{ content.wick }}: {{ wickText }}
              </div>
              <div class="col-span-1 whitespace-nowrap text-align-end">
                {{ wickPrice }} {{ content.iqd }}
              </div>
            </div>
            <div
              class="flex justify-between border-b border-grayBorder py-4 my-2"
            >
              <div class="col-span-2 me-4 text-start">
                {{ content.color }}: {{ colorText }}
              </div>
              <div class="col-span-1 whitespace-nowrap text-align-end">
                {{ colorPrice }} {{ content.iqd }}
              </div>
            </div>
            <div
              class="flex justify-between border-b border-grayBorder py-4 my-2"
            >
              <div class="col-span-2 me-4 text-start">
                {{ content.packagingOption }}: {{ packagingOption }}
              </div>
              <div class="col-span-1 whitespace-nowrap text-align-end">
                {{ packagingPrice }} {{ content.iqd }}
              </div>
            </div>
            <div
              class="flex justify-between border-b border-grayBorder py-4 my-2"
            >
              <div class="col-span-2 me-4 text-start">
                {{ content.brandedLabel }}: {{ brandedLabel }}
              </div>
              <div class="col-span-1 whitespace-nowrap text-align-end">
                {{ labelPrice }} {{ content.iqd }}
              </div>
            </div>

            <div
              class="
                flex
                justify-end
                border-b border-grayBorder
                py-4
                text-size-16
              "
            >
              <div class="flex w-56 justify-between">
                <div class="whitespace-nowrap">{{ content.totalPerItem }}:</div>
                <div class="text-align-end whitespace-nowrap">
                  {{ totalPerItem }} {{ content.iqd }}
                </div>
              </div>
            </div>
            <div
              class="
                flex
                justify-end
                flex-wrap
                border-b border-grayBorder
                py-4
                text-size-16
              "
            >
              <div class="flex w-56 justify-between">
                <div class="whitespace-nowrap">{{ content.quantity }}:</div>
                <div class="text-align-end whitespace-nowrap">
                  x {{ quantity }}
                </div>
              </div>
            </div>

            <div
              class="
                flex
                justify-between
                flex-wrapborder-b
                border-grayBorder
                py-4
                text-size-16
              "
            >
              <div></div>
              <div class="col-span-4 whitespace-nowrap"></div>
            </div>
            <div
              class="
                flex
                justify-between
                flex-wrap
                gap-5
                text-size-16
                pb-6
                flex-col-reverse
                items-end
              "
            >
              <div class="w-40 flex items-center justify-between">
                <div class="col-span-2 flex items-center whitespace-nowrap">
                  {{ content.total }}:
                </div>
                <div
                  class="
                    col-span-2
                    flex
                    items-center
                    justify-end
                    text-align-end
                    whitespace-nowrap
                  "
                >
                  {{ totalPrice }} {{ content.iqd }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
export default {
  inject: ["content"],
  data() {
    return {
      hide: "none",
      output: null,
      printing: false,
    };
  },
  props: [
    "containerPrice",
    "containerText",
    "scentText",
    "scentPrice",
    "wickPrice",
    "wickText",
    "colorPrice",
    "colorText",
    "totalPerItem",
    "quantity",
    "totalPrice",
    "packagingOption",
    "packagingPrice",
    "labelPrice",
    "brandedLabel",
    "request",
  ],
  methods: {
    async print() {
      let img;
      this.printing = true;
      await setTimeout(function () {
        console.log("printing");
      }, 2000);
      var rand = Math.floor(Math.random() * 1000000000);
      var pdf = new jsPDF("p", "mm", "a4");
      html2canvas(this.$refs.receipt, {
        quality: 10,
        scale: 1,
        dpi: 400,
      })
        .then(function (canvas) {
          img = canvas.toDataURL();
          setTimeout(function () {
            const imgProps = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);

            // pdf.save("Estimate_" + rand + ".pdf");
            window.open(
              pdf.output("bloburl", { filename: "Estimate_" + rand }),
              "_blank"
            );
          }, 300);
        })
        .catch((error) => {
          alert(error);
        });
      this.printing = false;
    },
  },
  // computed: {
  //   user() {
  //     return this.$store.getters["user/user"];
  //   },
  // },
};
</script>