<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.chooseContainer }}
    </div>
    <div
      class="mt-12 flex justify-center items-center flex-wrap"
      v-if="containers.length > 0"
    >
      <ContainerCard
        v-for="item in containers"
        :key="item.id"
        :container="item"
        :request="request"
        @setContainer="setContainer"
      />
    </div>
    <ContainerCardSkeleton v-else />
    <p class="error text-center mt-8 h-8">
      {{ error }}
    </p>
  </div>
</template>

<script>
import ContainerCard from "/src/components/ui/WhiteLabel/ContainerCard.vue";
import ContainerCardSkeleton from "/src/components/ui/Skeletons/ContainerCardSkeleton.vue";

export default {
  components: {
    ContainerCard,
    ContainerCardSkeleton,
  },
  emits: ["setContainer"],
  inject: ["content"],
  props: ["request", "error"],
  computed: {
    containers() {
      return this.$store.getters["materials/containers"];
    },
  },
  methods: {
    setContainer(value, container) {
      this.$emit("setContainer", value, container);
    },
  },
};
</script>
