<template>
  <div class="container-card flex md:flex-row flex-col my-4">
    <img :src="container.image" class="container-card-img" />
    <div class="p-8 container-card-content">
      <div class="text-size-16 lora-bold text-start mb-8">
        {{ container.title }}
      </div>
      <div class="flex flex-wrap">
        <ContainerRadioButton
          v-for="size in container.sizes"
          :key="size.id"
          :title="size.size.title"
          :price="size.price"
          :value="size.id"
          :selectedItem="request.containerSize"
          class="m-0 mb-2"
          @setContainer="setContainer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContainerRadioButton from "/src/components/ui/Buttons/ContainerRadioButton.vue";
export default {
  components: {
    ContainerRadioButton,
  },
  emits: ["setContainer"],
  props: ["container", "request"],
  methods: {
    setContainer(value) {
      this.$emit("setContainer", value, this.container.id);
    },
  },
};
</script>