<template>
  <div class="flex items-start w-max-content" @click="$emit('setValue')">
    <div
      class="checkbox-fake-check"
      :class="{ 'selected-radio': selectedItem == true }"
    >
      <i class="fas fa-check"></i>
    </div>
    <p class="ms-2 smd:w-full w-60">{{ text }}</p>
  </div>
</template>

<script>
export default {
  emits: ["setValue"],
  props: ["text", "value", "selectedItem"],
  inject: ["content"],
};
</script>