<template>
  <div
    class="relative w-max-content scent-radio my-2 mx-6"
    @click="$emit('setWick', value)"
    :class="{ 'selected-scent': selectedItem == value }"
  >
    <img :src="img" class="mt-3" />
    <div class="text-center mt-2">
      <div :class="{ 'text-bluePrimary': selectedItem == value }">
        {{ title }}
      </div>
      <div class="lora-bold mt-2">+{{ price }} {{ content.iqd }}</div>
    </div>
    <div
      class="radio-fake-check"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["setWick"],
  props: ["title", "price", "value", "selectedItem", "img"],
  inject: ["content"],
};
</script>