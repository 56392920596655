<template>
  <div
    class="relative w-max-content scent-radio my-2 mx-6"
    @click="$emit('setScent', value)"
    :class="{ 'selected-scent': selectedItem == value }"
  >
    <img :src="img" class="mt-3" />
    <div
      class="lora-bold mt-2"
      :class="{ 'text-bluePrimary': selectedItem == value }"
    >
      {{ title }}
    </div>
    <div
      class="radio-fake-check"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["setScent"],
  props: ["title", "value", "selectedItem", "img"],
  inject: ["content"],
};
</script>