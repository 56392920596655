<template>
  <div class="request-tabs relative flex justify-around">
    <div class="tabs-underline"></div>
    <div class="tab">
      <div :class="currentTab == 1 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/container.png"
          class="container-icon"
          v-if="currentTab != 1"
        />
        <img
          src="/static/images/icons-2/container-w.png"
          class="container-icon"
          v-if="currentTab == 1"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.container }}</div>
    </div>
    <div class="tab">
      <div :class="currentTab == 2 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/scent.png"
          class="h-7 object-contain"
          :class="{ 'opacity-50': currentTab != 2 }"
          v-if="currentTab != 2"
        />
        <img
          src="/static/images/icons-2/scent-w.png"
          class="h-7 object-contain"
          :class="{ 'opacity-50': currentTab != 2 }"
          v-if="currentTab == 2"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.scent }}</div>
    </div>
    <div class="tab">
      <div :class="currentTab == 3 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/wick-w.png"
          class="wick-icon object-contain h-10"
          :class="{ 'opacity-50': currentTab != 3 }"
          v-if="currentTab != 3"
        />
        <img
          src="/static/images/icons-2/wick.png"
          class="wick-icon object-contain h-10"
          :class="{ 'opacity-50': currentTab != 3 }"
          v-if="currentTab == 3"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.wick }}</div>
    </div>
    <div class="tab">
      <div :class="currentTab == 4 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/color.png"
          class="color-icon"
          :class="{ 'opacity-50': currentTab != 4 }"
          v-if="currentTab != 4"
        />
        <img
          src="/static/images/icons-2/color-w.png"
          class="color-icon"
          :class="{ 'opacity-50': currentTab != 4 }"
          v-if="currentTab == 4"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.color }}</div>
    </div>
    <div class="tab">
      <div :class="currentTab == 5 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/package.png"
          class="h-8"
          v-if="currentTab != 5"
        />
        <img
          src="/static/images/icons-2/package-w.png"
          class="h-8"
          v-if="currentTab == 5"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.labelPackage }}</div>
    </div>
    <div class="tab">
      <div :class="currentTab == 6 ? 'active-icon' : 'icon-background'">
        <img
          src="/static/images/icons-2/quantity.png"
          class="h-8"
          :class="{ 'opacity-50': currentTab != 5 }"
          v-if="currentTab != 5"
        />
        <img
          src="/static/images/icons-2/quantity-w.png"
          class="h-8"
          :class="{ 'opacity-50': currentTab != 5 }"
          v-if="currentTab == 5"
        />
      </div>
      <div class="text-center mt-2 tab-text">{{ content.quantity }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentTab"],
  inject: ["content"],
};
</script>