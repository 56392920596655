<template>
  <div
    class="relative w-max-content scent-radio my-2 mx-6"
    @click="$emit('setPackage', value)"
    :class="{ 'selected-scent': selectedItem == value }"
  >
    <img :src="img" class="mt-3" />
    <div class="text-center">
      <div>{{ title }}</div>
      <div class="lora-bold mt-2">+{{ format(price) }} {{ content.iqd }}</div>
    </div>
    <div
      class="radio-fake-check"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  emits: ["setPackage"],
  props: ["title", "price", "value", "selectedItem", "img"],
  inject: ["content"],
  methods: {
    format(price) {
      return format(price);
    },
  },
};
</script>