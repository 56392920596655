<template>
  <div>
    <div class="grid grid-cols-2 gap-x-0 lg:gap-x-10 mt-16 mb-12">
      <div class="col-span-2 flex-col items-start flex lg:col-span-1">
        <div class="text-size-20">{{ content.whiteLabelTitle }}</div>
        <div class="col-span-2 w-full md:col-span-1">
          <div class="flex items-start flex-col sm:flex-row mt-8">
            <label
              for="quantity"
              class="input-label mt-3 mb-2 sm:mb-0 flex items-center"
            >
              {{ content.quantity }}
            </label>
            <div class="w-full">
              <input
                type="number"
                class="form-input"
                v-model.number="quantity"
                @change="setMeta('quantity')"
                min="10"
                id="quantity"
                :placeholder="content.quantity"
              />
              <p v-if="showErrors" class="error text-sm mt-2 block w-full">
                {{ errors?.quantity }}
              </p>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row mt-8">
            <label for="notes" class="input-label mb-2 sm:mb-0 flex">
              {{ content.notes }}
            </label>
            <textarea
              type="text"
              style="resize: none"
              class="form-input h-40"
              v-model.trim="notes"
              @change="setMeta('notes')"
              id="notes"
              :placeholder="content.whiteLabelNotes"
            />
          </div>
          <div class="flex flex-col sm:flex-row mt-8">
            <label
              for="name"
              class="input-label mb-2 sm:mb-0 flex items-start mt-0 smd:mt-3"
            >
              {{ content.name }}
            </label>
            <div class="w-full">
              <input
                type="text"
                class="form-input"
                v-model.trim="name"
                @change="setMeta('name')"
                id="name"
                :placeholder="content.name"
              />
              <p v-if="showErrors" class="error text-sm mt-2">
                {{ errors?.name }}
              </p>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row mt-8">
            <label
              for="email"
              class="input-label flex mb-2 sm:mb-0 items-start mt-0 smd:mt-3"
            >
              {{ content.email }}
            </label>
            <div class="w-full">
              <input
                type="email"
                class="form-input"
                v-model.trim="email"
                @change="setMeta('email')"
                id="email"
                placeholder="example@companyname.com"
              />
              <p v-if="showErrors" class="error text-sm mt-2">
                {{ errors?.email }}
              </p>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row mt-8">
            <label
              for="phone"
              class="input-label mb-2 sm:mb-0 flex items-start mt-0 sm:mt-3"
            >
              {{ content.phone }}
            </label>
            <div class="w-full">
              <input
                type="text"
                class="form-input"
                v-model.trim="phone"
                @change="setMeta('phone')"
                id="phone"
                placeholder="07xx xxx xxx"
              />
              <p v-if="showErrors" class="error text-sm mt-2">
                {{ errors?.phone }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <OrderSummery
          :request="request"
          :containerText="`${container?.title}${content.coma} ${size?.size?.title}`"
          :containerPrice="format(size?.price)"
          :colorText="color?.title"
          :colorPrice="format(color.label)"
          :wickText="wick?.title"
          :wickPrice="format(wick?.price)"
          :scentText="scent?.title"
          :totalPrice="format(totalPrice)"
          :quantity="format(quantity)"
          :packagingOption="requestPackage?.title"
          :totalPerItem="format(price)"
          :packagingPrice="format(requestPackage?.price)"
          :brandedLabel="request.label ? content.included : content.notIncluded"
          :labelPrice="request.label ? format(labelPrice) : format(0)"
        />
      </div>
    </div>
    <div class="w-full flex justify-center">
      <Alert
        v-if="success"
        customClasses="max-w-550"
        type="success"
        :title="content.quoteSuccess"
        :message="content.youWillBeContacted"
      >
        <router-link
          to="/"
          class="
            alert-btn
            text-text-white text-white
            lora-bold
            whitespace-nowrap
            bg-secondaryColor
            duration-300
            mt-6
          "
        >
          {{ content.goHome }}
        </router-link>
      </Alert>
    </div>
    <div class="w-full flex justify-center">
      <Alert
        v-if="requestError"
        customClasses="max-w-550"
        type="fail"
        :title="content.fail"
        :message="content.quoteFail"
      >
        <router-link
          to="/"
          class="
            alert-btn
            text-text-white text-white
            lora-bold
            whitespace-nowrap
            bg-secondaryColor
            duration-300
            mt-6
          "
        >
          {{ content.goHome }}
        </router-link>
      </Alert>
    </div>
  </div>
</template>

<script>
import OrderSummery from "/src/components/WhiteLabelRequest/OrderSummery.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: { OrderSummery },
  inject: ["content"],
  props: ["request", "success", "requestError", "errors", "showErrors", "user"],
  emits: ["setMeta", "hideFeedback"],
  data() {
    return {
      phone: null,
      quantity: null,
      email: null,
      name: null,
      notes: null,
    };
  },
  computed: {
    container() {
      return this.$store.getters["materials/container"](this.request.container);
    },
    size() {
      return this.container?.sizes?.filter(
        (item) => item.id == this.request.containerSize
      )[0];
    },
    wick() {
      return this.$store.getters["materials/wick"](this.request.wick);
    },
    scent() {
      return this.$store.getters["materials/scent"](this.request.scent);
    },
    color() {
      return this.$store.getters["materials/color"](this.request.color);
    },
    requestPackage() {
      return this.$store.getters["materials/onePackage"](this.request.package);
    },
    labelPrice() {
      return this.$store.getters["label/price"];
    },
    price() {
      // $total = ($container->size->value / 100 *  $color->wl_price) + $container->price;
      let price =
        parseFloat(this.size.size.value / 100) * parseFloat(this.color.label) +
        parseFloat(this.size.price);

      if (this.request.label) price += parseFloat(this.labelPrice);
      if (this.request.package) price += parseFloat(this.requestPackage.price);
      if (this.request.wick) price += parseFloat(this.wick.price);
      return price;
    },
    totalPrice() {
      return this.quantity * this.price;
    },
  },
  methods: {
    setMeta(type) {
      this.$emit("setMeta", type, this[type]);
    },
    hideFeedback() {
      this.$emit("hideFeedback");
    },
    format(number) {
      return format(number);
    },
  },
};
</script>
