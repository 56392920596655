<template>
  <div class="request-page-title layout-padding text-center my-16">
    <div class="text-size-24">{{ content.orderWhiteLabelCandles }}</div>
    <div class="mt-2">{{ content.orderWhiteLabelCandlesContent }}</div>
  </div>
  <div class="stepper-container">
    <RequestTabs :currentTab="currentTab" />
    <div class="grid grid-cols-11">
      <div class="col-span-9 lg:col-span-7 col-start-2 lg:col-start-3">
        <transition name="fade" mode="out-in">
          <Container
            v-if="currentTab == 1"
            :request="request"
            :error="errors.container"
            @setContainer="setContainer"
          />
          <Scent
            v-else-if="currentTab == 2"
            :scent="request.scent"
            :error="errors.scent"
            @setScent="setScent"
          />
          <Wick
            v-else-if="currentTab == 3"
            :wick="request.wick"
            :error="errors.wick"
            @setWick="setWick"
          />
          <Color
            v-else-if="currentTab == 4"
            :color="request.color"
            :error="errors.color"
            @setColor="setColor"
          />
          <Package
            v-else-if="currentTab == 5"
            :request="request"
            :errors="errors"
            @setPackage="setPackage"
            @setLabel="setLabel"
            @setLogo="setLogo"
          />
          <Quantity
            v-else-if="currentTab == 6"
            :request="request"
            :success="success"
            :request-error="error"
            :errors="errors"
            :show-errors="showErrors"
            @setMeta="setMeta"
            @hideFeedback="hideFeedback"
          />
        </transition>
      </div>
    </div>

    <div class="flex justify-center items-center pb-8">
      <button class="prev-tab-button me-3" @click="prevTab">
        {{ content.back }}
      </button>
      <button
        class="
          next-tab-button
          header-btn
          hover:text-bluePrimary
          text-white
          bg-bluePrimary
          lora-bold
          whitespace-nowrap
          hover:bg-blueSecondary
          duration-300
        "
        @click="nextTab"
        v-if="currentTab < 6"
      >
        {{ content.next }}
      </button>
      <button
        v-else-if="canRequest"
        class="
          next-tab-button
          header-btn
          hover:text-bluePrimary
          text-white
          bg-bluePrimary
          lora-bold
          whitespace-nowrap
          hover:bg-blueSecondary
          duration-300
        "
        @click="recaptcha"
      >
        {{ content.order }}
      </button>
      <button v-else class="btn-signIn w-max-content lora-bold" disabled>
        {{ content.order }}
      </button>
    </div>
  </div>
</template>

<script>
import RequestTabs from "/src/components/WhiteLabelRequest/RequestTabs.vue";
import Container from "/src/components/WhiteLabelRequest/Container.vue";
import Scent from "/src/components/WhiteLabelRequest/Scent.vue";
import Wick from "/src/components/WhiteLabelRequest/Wick.vue";
import Color from "/src/components/WhiteLabelRequest/Color.vue";
import Package from "/src/components/WhiteLabelRequest/Package.vue";
import Quantity from "/src/components/WhiteLabelRequest/Quantity.vue";

import addPhoto from "../../../imageUpload";

export default {
  components: { RequestTabs, Container, Scent, Wick, Color, Package, Quantity },
  inject: ["content"],
  data() {
    return {
      currentTab: 1,
      success: false,
      error: false,
      request: {
        container: null,
        containerSize: null,
        scent: null,
        wick: null,
        color: null,
        package: null,
        label: false,
        quantity: null,
        name: "",
        email: "",
        notes: "",
        phone: "",
      },
      errors: {
        container: null,
        scent: null,
        color: null,
        wick: null,
        package: null,
        logo: null,
        quantity: null,
        name: null,
        email: null,
        phone: null,
      },
      logo: null,
      canRequest: false,
      showErrors: false,
      token: null,
    };
  },
  mounted() {
    this.$store.dispatch("label/labelPrice");
  },
  watch: {
    request: {
      handler() {
        if (!this.validation()) return;
        this.canRequest = true;
      },
      deep: true,
    },
  },
  methods: {
    nextTab() {
      switch (this.currentTab) {
        case 1:
          if (!this.request.container) {
            this.errors.container = this.content.chooseOption;
            return;
          }
          if (this.request.container) this.next();
          break;
        case 2:
          if (!this.request.scent) {
            this.errors.scent = this.content.chooseOption;
            return;
          }
          if (this.request.scent) this.next();
          break;
        case 3:
          if (!this.request.wick) {
            this.errors.wick = this.content.chooseOption;
            return;
          }
          if (this.request.wick) this.next();
          break;
        case 4:
          if (!this.request.color) {
            this.errors.color = this.content.chooseOption;
            return;
          }
          if (this.request.color) this.next();
          break;
        case 5:
          if (!this.request.package) {
            this.errors.package = this.content.chooseOption;
            return;
          }
          if (this.request.label && !this.logo) {
            this.errors.logo = this.content.addLogo;
            return;
          }
          if (this.request.package) this.next();
          break;
        default:
          break;
      }
      window.scrollTo(0, 0);
    },
    prevTab() {
      if (this.currentTab > 1) {
        this.currentTab = this.currentTab - 1;
      }
    },
    next() {
      if (this.currentTab < 6) {
        this.currentTab = this.currentTab + 1;
      }
    },
    setContainer(value, container) {
      this.request.containerSize = value;
      this.request.container = container;
      this.errors.container = null;
    },
    setScent(value) {
      this.request.scent = value;
      this.errors.scent = null;
    },
    setWick(value) {
      this.request.wick = value;
      this.errors.wick = null;
    },
    setColor(value) {
      this.request.color = value;
      this.errors.color = null;
    },
    setPackage(value) {
      this.request.package = value;
      this.errors.package = null;
    },
    setLabel(value = false) {
      if (value) this.request.label = true;
      else this.request.label = !this.request.label;
    },
    setLogo(value) {
      this.logo = value;
    },
    setMeta(type, value) {
      this.request[type] = value;
      if (type == "quantity") this.errors[type] = null;
      else this.errors[type] = "";
    },
    hideFeedback() {
      this.success = false;
      this.error = false;
    },
    validation() {
      let validation = true;

      if (!this.request.quantity) {
        validation = false;
        this.errors.quantity = this.content.enterQuantity;
      }
      if (this.request.name == "") {
        validation = false;
        this.errors.name = this.content.enterName;
      }
      if (this.request.email == "") {
        validation = false;
        this.errors.email = this.content.enterEmail;
      }
      if (this.request.phone == "") {
        validation = false;
        this.errors.phone = this.content.enterPhone;
      }

      // console.log(validation, this.errors);

      return validation;
    },
    async recaptcha() {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc", {
            action: "submit",
          })
          .then((token) => {
            this.token = token;
            this.submitRequest();
          });
      });
    },
    async submitRequest() {
      this.showErrors = false;
      if (!this.validation()) {
        this.showErrors = true;
        return;
      }

      this.error = null;
      this.canRequest = false;
      try {
        let link = null;
        if (this.logo) link = await addPhoto(this.logo);
        let input = { ...this.request, token: this.token };
        if (link) input.logo = link;

        await this.$store.dispatch("label/request", input);
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.requestSuccess,
        });
        this.canRequest = true;
        this.$router.replace("/");
        this.success = true;
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.quoteFail,
        });
        // this.error = true;
        this.canRequest = true;
      }
    },
  },
};
</script>
