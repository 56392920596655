<template>
  <div
    class="relative w-max-content container-radio my-2 mx-6"
    @click="$emit('setColor', value)"
    :class="{ 'selected-container': selectedItem == value }"
  >
    <div class="flex items-center justify-between">
      <div
        class="text-start me-2"
        :class="{ 'selected-container': selectedItem == value }"
      >
        <div>{{ title }}</div>
        <div class="lora-bold">+{{ format(price) }} {{ content.iqd }}</div>
      </div>
      <div class="color-radio-circle" :style="`background:${color}`"></div>
    </div>
    <div
      class="color-fake-check"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
  </div>
</template>
<script>
import { format } from "/src/assets/numberFormat";

export default {
  emits: ["setColor"],
  props: ["title", "price", "value", "selectedItem", "color"],
  inject: ["content"],
  methods: {
    format(price) {
      return format(price);
    },
  },
};
</script>