<template>
  <div
    class="relative w-max-content container-radio my-2 sm:me-6 me-2"
    @click="$emit('setContainer', value)"
    :class="{ 'selected-container': selectedItem == value }"
  >
    <div
      class="text-start"
      :class="{ 'selected-container': selectedItem == value }"
    >
      <div>{{ title }}</div>
      <div v-if="price" class="lora-bold">
        {{ format(price) }} {{ content.iqd }}
      </div>
    </div>
    <div
      class="radio-fake-check"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  emits: ["setContainer"],
  props: ["title", "price", "value", "selectedItem"],
  inject: ["content"],
  methods: {
    format(price) {
      return format(price);
    },
  },
};
</script>