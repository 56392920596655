<template>
  <div class="container-card-skeleton w-full flex md:flex-row flex-col my-4">
    <img class="container-card-img" />
    <div class="p-6 container-card-content">
      <div class="item-title"></div>
      <div class="flex flex-wrap mt-4">
        <div class="radio my-2 sm:me-6 me-2 m-0 mb-2"></div>
        <div class="radio my-2 sm:me-6 me-2 m-0 mb-2"></div>
        <div class="radio my-2 sm:me-6 me-2 m-0 mb-2"></div>
      </div>
    </div>
  </div>
</template>

