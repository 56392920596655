<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.packagingOptions }}
    </div>
    <div class="flex flex-col items-center">
      <div class="mt-12 flex justify-center items-center flex-wrap">
        <PackageRadio
          v-for="item in packages"
          :key="item.id"
          :title="item.title"
          :value="item.id"
          :price="item.price"
          :selectedItem="request.package"
          @setPackage="setPackage"
          :img="item.image"
        />
      </div>
      <p class="error text-center mt-8 h-8">
        {{ errors?.package }}
      </p>
      <div>
        <CustomCheckbox
          class="mt-7 cursor-pointer"
          :text="content.brandSticker"
          @setValue="setLabel"
          :selectedItem="request.label"
        />
      </div>
      <div class="flex flex-row items-center mt-6">
        <div class="">
          <label
            for="file"
            class="smd:w-full sm:w-72 w-48 block upload-input upload-input-p-0"
          >
            <input type="file" id="file" class="hidden" @change="setLogo" />
            <div
              class="
                flex
                justify-between
                w-full
                border-with-radius
                cursor-pointer
              "
            >
              <div class="flex items-center ms-4">{{ content.uploadFile }}</div>
              <div class="upload-select lora-bold">{{ content.select }}</div>
            </div>
          </label>
        </div>
        <img v-if="showLogo" :src="showLogo" class="h-20 w-20 rounded ms-5" />
        <div
          v-else
          class="
            h-20
            w-20
            grid
            place-content-center
            bg-blueLight
            rounded
            ms-5
            text-bluePrimary
            font-semibold
          "
        >
          {{ content.logo }}
        </div>
      </div>
      <p class="error text-center mt-8 h-8">
        {{ errors?.logo }}
      </p>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "/src/components/ui/CustomCheckbox.vue";
import PackageRadio from "/src/components/ui/Buttons/PackageRadio.vue";

export default {
  data() {
    return {
      selectedPackage: null,
      logo: null,
      showLogo: null,
    };
  },
  emits: ["setPackage", "setLabel", "setLogo"],
  inject: ["content"],
  props: ["request", "errors"],
  components: {
    PackageRadio,
    CustomCheckbox,
  },
  computed: {
    packages() {
      return this.$store.getters["materials/packages"];
    },
  },
  methods: {
    setPackage(value) {
      this.$emit("setPackage", value);
    },
    setLabel() {
      this.$emit("setLabel");
    },
    setLogo(event) {
      this.error = null;

      let image = event.target.files[0];

      if (image.size / 1024 > 2500) {
        this.error = this.content.largeFile;
        return;
      }

      if (image["type"].split("/")[0] != "image") {
        this.error = this.content.fileType;
        return;
      }
      // this.image = image;
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = async (e) => {
        this.showLogo = e.target.result;
        this.$emit("setLogo", image);
        this.$emit("setLabel", true);
      };
    },
  },
};
</script>
